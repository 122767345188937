
































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import moment from "moment-jalaali";
import { AxiosResponse } from "axios";
import DatetimePicker from "vue-persian-datetime-picker";
import { UserApi } from "@/networks/UserApi";
import Office from "@/views/components/inputs/office.vue";
import { EventApi } from "@/networks/EventApi";
import Province from "@/views/components/inputs/province.vue";
import Enum from "@/config/enum";

@Component({
  components: {
    Province,
    DatetimePicker,
    Office: Office
  }
})
export default class UserEventPublic extends Vue {
  private selectedProvince = 0;
  private apiUrl = process.env.VUE_APP_URL || "https://api.bms-go2tr.com";
  private types = Enum.eventTypesQuickWithPrice;
  private displayType = this.types;
  private date = {
    year: 1401,
    month: 9,
    day: ""
  };
  private month = Enum.months;
  private mentor: Record<string, string> = {
    fname: "",
    lname: "",
    avatar: ""
  };
  private times: Array<Record<string, string>> = [];
  private days: Array<Record<string, string>> = [];
  private form = {
    summery: "",
    type: "",
    office_id: "",
    date: "",
    time: ""
  };
  private hideOffice = false;
  private errors: Record<string, Array<string>> = {};
  private showEventType = true;
  private showOffice = true;
  province_error = false;
  mounted() {
    document.title = (this.$route.meta as any).title || "GO2TR BMS";
    if (this.$route.params.id && this.$route.params.id != '0') {
      this.getMentor();
    }
    this.getDays();
    for (let i = 10; i <= 21; i++) {
      this.times.push({
        text: `ساعت ${i}:00`,
        value: `${("00" + i).substr(-2)}:00`,
        cnt: "0"
      });
    }
    this.date.month =
      moment().format("jMM") == "12" ? "01" : moment().format("jM");
  }

  public async submit(): Promise<void> {
    try {
      let form: any = this.$refs.form;
      const validate = form.validate();
      if (!this.selectedProvince) {
        this.province_error = true;
        return;
      }
      if (!validate) {
        return;
      }
      const d: any = {
        creator_id: undefined,
        ...this.form
      };
      if (this.$route.params.id) {
        d.creator_id = this.$route.params.id;
      }
      const res: AxiosResponse = await new EventApi().quickRegisterPublic(d);
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
      this.errors = {};
      this.form = {
        summery: "",
        type: "",
        office_id: "",
        date: "",
        time: ""
      };
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      let data = res.data.data;
      window.location.href =
        process.env.VUE_APP_BMS +
        `/pay/${data.event.id}/` +
        data.user.mobile.substr(-10);
    } catch (error: any) {
      if (error.response && error.response?.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async getMentor(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().getMentorPublic(
        this.$route.params.id
      );
      this.mentor = res.data.data;
    } catch (error: any) {
      console.log(error);
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async checkAvailability(): Promise<void> {
    if (this.date.day) {
      try {
        const res: AxiosResponse = await new UserApi().checkAvailability({
          office_id:
            this.form.type == "phone" ||
            this.form.type == "expert-doctor-online" ||
            this.form.type == "managers-online"
              ? `5`
              : `${this.form.office_id}`,
          date: this.form.date || moment().format("YYYY/MM/DD"),
          type: this.form.type
        });
        const times: Record<string, string>[] = [];
        this.times.map((x) => {
          let insert = false;
          res.data.data.forEach((y: { time: string; cnt: string }) => {
            if (y.time.substr(0, 5) == x.value) {
              times.push({
                text: `ساعت ${x.value} ${y.cnt ? "" : "(ناموجود)"}`,
                value: `${x.value}`,
                cnt: `${y.cnt}`
              });
              insert = true;
            }
          });
          if (!insert) {
            times.push(x);
          }
        });
        this.times = times;
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  @Watch("date.day")
  @Watch("date.month")
  public onDateChange(val: string, oldVal: string): void {
    console.log(this.date);
    if (val != oldVal) {
      this.form.date = `${this.date.year}/${("0" + this.date.month).substr(
        -2
      )}/${("0" + this.date.day).substr(-2)}`;
      this.checkAvailability();
    }
  }

  @Watch("form.office_id")
  public onOfficeChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.checkAvailability();
    }
  }

  @Watch("selectedProvince")
  public onProvince(val: number, oldVal: number): void {
    this.province_error = false;
    if (val != oldVal) {
      // switch (val) {
      //   // case 5: // tabriz office
      //   //   this.form.type = "here";
      //   //   this.form.office_id = "1";
      //   //   this.officeSelected = true;
      //   //   this.hideOffice = true;
      //   //   this.displayTypes(true);
      //   //   break;
      //   // case 8: // tehran office
      //   //   this.form.type = "here";
      //   //   this.form.office_id = "3";
      //   //   this.officeSelected = true;
      //   //   this.hideOffice = true;
      //   //   this.displayTypes(true);
      //   //   break;
      //   // case 17: // shiraz office
      //   //   this.form.type = "here";
      //   //   this.form.office_id = "4";
      //   //   this.officeSelected = true;
      //   //   this.displayTypes(true);
      //   //   this.hideOffice = true;
      //   //   break;
      //   // case 11: // mashhad office
      //   //   this.form.type = "here";
      //   //   this.form.office_id = "2";
      //   //   this.displayTypes(true);
      //   //   this.officeSelected = true;
      //   //   this.hideOffice = true;
      //   //   break;
      //
      //   default:
      //     this.form.type = "";
      //     this.officeSelected = false;
      //     this.displayTypes(false);
      //     this.hideOffice = false;
      //     break;
      // }

      this.form.type = "";
      this.officeSelected = false;
      this.displayTypes(false);
      this.hideOffice = false;
    }
  }

  @Watch("date.month")
  public onMonthChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.getDays();
    }
  }

  @Watch("form.type")
  public onTypeChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      if (Enum.onlineTypes.includes(val)) {
        this.form.office_id = "";
        const times = [];
        for (let i = 10; i <= 21; i++) {
          times.push({
            text: `ساعت ${i}:00`,
            value: `${("00" + i).substr(-2)}:00`,
            cnt: "0"
          });
        }
        this.times = times;
      } else {
        const times = [];
        for (let i = 11; i <= 19; i++) {
          times.push({
            text: `ساعت ${i}:00`,
            value: `${("00" + i).substr(-2)}:00`,
            cnt: "0"
          });
        }
        this.times = times;
      }

      if (!this.officeSelected)
        this.hideOffice = Enum.onlineTypes.includes(val);
    }
  }
  private officeSelected = false;

  private getDays(): void {
    let currentMonth = moment().format("jM");
    let currentDay = Number.parseInt(moment().format("jD"));
    let days = [];
    if (currentMonth == this.date.month) {
      for (let i = 2; i <= 31 - currentDay; i++) {
        let isFriday = moment(
          `${this.date.year}/${this.date.month}/${currentDay + i}`,
          "jYYYY/jMM/jD"
        ).format("d");
        if (isFriday == "5") continue;
        days.push({
          text: `${currentDay + i}`,
          value: `${currentDay + i}`
        });
      }
    } else {
      for (let i = 1; i <= 31; i++) {
        let isFriday = moment(
          `${this.date.year}/${this.date.month}/${i}`,
          "jYYYY/jMM/jD"
        ).format("d");
        if (isFriday == "5") continue;
        days.push({
          text: `${i}`,
          value: `${i}`
        });
      }
    }
    this.days = days;
  }

  private displayTypes(onlyHere = false) {
    const types = [];
    for (const x of this.types) {
      if (Enum.onlineTypes.includes(x.value) && onlyHere) {
        continue;
      }
      types.push(x);
    }
    this.displayType = types;
  }
}
